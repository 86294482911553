import * as React from "react";
import { navigate } from "gatsby";

// Imports Material Design
import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './src/assets/mdbreact/scss/mdb-free.scss';
import "react-multi-carousel/lib/styles.css";
import { GGgetMode, GGsaveMode } from "./src/services/gglocalstorage";

export function wrapPageElement({ element, props }) {
	let pathname = location.pathname;
    return (
		<>
			{GGgetMode() && (pathname.startsWith("/gg") || pathname.startsWith("/cursos")) && <link rel="stylesheet" href="/gg-mdb-free.css"></link>}
			<div {...props}>{element}</div>
		</>
	);
}

// export function onRouteUpdate({ location, prevLocation }) {
// 	if(location.pathname.startsWith("/gg") && !GGgetMode())
// 		GGsaveMode(true);
// }

export function onInitialClientRender() {
	if(location.pathname.startsWith("/gg") && !GGgetMode()){
		GGsaveMode(true, false);
		navigate(location.pathname);
	}
}