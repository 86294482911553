/* eslint-disable no-undef */
import { navigate } from "gatsby";

/*
    Serviço responsável por ler e escrever os valores de registro da Skin ativa.
    Consumir daqui quando realizar consultas.
*/

/**
 * Salva um valor para a troca de skins da aplicação.
 * @param {bool} value Valor da skin (INSTITUCIONAL = 0/GAMER = 1)
 */
export function GGsaveMode(value, updateRoute = true) {
	if (typeof window === "undefined") return;
	// Para rodar apenas no navegador
	window.localStorage.setItem("feevaleWayMode", value);

	if(updateRoute)
		UpdateRoute();
}

/**
 * Recupera o valor da skin atual selecionada.
 * @returns bool
 */
export function GGgetMode() {
	// MODO INSTITUCIONAL = 0
	// MODO GAMER = 1
	if (typeof window === "undefined") return;
	//return window.localStorage.getItem("feevaleWayMode") === "true";
	return false
}

function UpdateRoute() {  
	let path = window.location.pathname;

	// Caso seja /cursos (não possui árvore de urls específica), apenas recarrega
	if (path.startsWith("/cursos")) {
		navigate(path);
	} else {
		if (GGgetMode() && !path.startsWith("/gg")) {
			// Caso modo GG, insere o direcionamento para a árvore /gg
			navigate(`/gg${path}`);
		} else {
			// Caso contrário, remove o prefixo /gg
			navigate(`${path.substring(3)}`);
		}
	}
}