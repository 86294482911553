// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conheca-a-feevale-way-js": () => import("./../../../src/pages/conheca-a-feevale-way.js" /* webpackChunkName: "component---src-pages-conheca-a-feevale-way-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-descontos-js": () => import("./../../../src/pages/descontos.js" /* webpackChunkName: "component---src-pages-descontos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscrevase-js": () => import("./../../../src/pages/inscrevase.js" /* webpackChunkName: "component---src-pages-inscrevase-js" */),
  "component---src-pages-licenciaturas-js": () => import("./../../../src/pages/licenciaturas.js" /* webpackChunkName: "component---src-pages-licenciaturas-js" */),
  "component---src-pages-polos-js": () => import("./../../../src/pages/polos.js" /* webpackChunkName: "component---src-pages-polos-js" */),
  "component---src-templates-cursos-disciplinas-js": () => import("./../../../src/templates/cursos/disciplinas.js" /* webpackChunkName: "component---src-templates-cursos-disciplinas-js" */),
  "component---src-templates-cursos-home-js": () => import("./../../../src/templates/cursos/home.js" /* webpackChunkName: "component---src-templates-cursos-home-js" */),
  "component---src-templates-cursos-js": () => import("./../../../src/templates/cursos.js" /* webpackChunkName: "component---src-templates-cursos-js" */)
}

